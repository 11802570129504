import React from "react";


import Routs from "./Routs";

import 'bootstrap/dist/css/bootstrap.min.css';
import './assets/css/style.css' 

function App() {

  return (
    <div style={{overflowX:'hidden'}}>
        <Routs/>
    
    </div>
  );
}

export default App;
